.Join{
    display: flex;
    padding: 0 2rem;
    gap: 15rem;
}
.Join.hook{
    overflow: visible;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20px;
    margin: -8px 0;
}

.right-j{
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 1rem;
    position: relative;
            


}
.email-container{
    display: flex;
    gap: 4rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}

::placeholder{
    color: var(--lightgray);
}

.btn-join{
    background-color: var(--orange);
    color: white;
    width: 7rem;
}

.alert-container{
    position: fixed;
    top: -0;
    left: 0;
    background: rgba(0, 0, 0, 0.795);
    height: 100vh;
    width: 100%;
    z-index: 100;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    display: none;
}

.alert-container.open{
    display: flex;
}
.alert-container>.msg-container{
    background: var(--planCard);
    margin: 0 2rem;
    padding: 1rem 1.2rem;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    max-width: 40rem;
    
}

.alert-container>.msg-container>.message-box{
    font-weight: 200;
    text-align: center;
    line-height: 1.5;
    color: rgba(0, 0, 0, 0.897);
}

.alert-container>.msg-container>.btn{
    align-self: flex-end;
    padding: .25rem .5rem;
    /* font-size: .85rem; */
}



/* Responsive Start */
@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
        
    }

    .left-j{
        font-size: x-large;
        flex-direction: column;
        text-align: center;
    }
    .left-j>hr{
        position: absolute;
        width: 10.5rem;
        border: 2px solid var(--orange);
        border-radius: 20px;
        margin: -15px 25%;
        
    }
    .right-j{
        padding: 2rem;
        
    }
    .email-container{
        gap: 0;
        position: relative;
        justify-content: center;
        z-index: 2;
    }

    button.btn.btn-join{
        font-size: small;
        max-height: 5rem;
        
    }

    .email-container>button{
        position: absolute;
        top: 5rem;
        padding: .5rem 1.5rem;
    }
}
/* Responsive Ends */